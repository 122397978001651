import { Alert, Tabs } from '@cloudscape-design/components';

import IncidentsTable from '../DeviceIncidents/IncidentsTable';
import AlertsTable from '../DeviceAlertMgr/AlertsTable';
import DeviceDetails from '../DeviceDetails';
import { Asset } from 'types/custom';

type DeviceTabsProps = {
    selectedDevices: Asset[] | undefined;
};

const DeviceTabs = ({ selectedDevices }: DeviceTabsProps) => {
    if (!selectedDevices || (Array.isArray(selectedDevices) && selectedDevices.length === 0)) {
        return (
            <Alert header="No device selected">Please select one to see details.</Alert>
        );
    }
    if (selectedDevices.length > 1) {
        return (
            <Alert header="Multiple devices selected">Please select only one to see details.</Alert>
        );
    }

    return (
        <Tabs
            tabs={[
                {
                    label: 'Details',
                    id: 'details',
                    content: <DeviceDetails selectedDevices={selectedDevices[0]} />,
                },
                {
                    label: 'Alert Rules',
                    id: 'alert-rules',
                    content: <AlertsTable selectedDevices={[selectedDevices[0]]} />,
                },
                {
                    label: 'Incidents',
                    id: 'incidents',
                    content: <IncidentsTable selectedDevices={selectedDevices[0]} />,
                },
            ]}
            variant='container'
        />
    );
};

export default DeviceTabs;
