// User Manager
export type UserRolesProps = {
    resourcetype: string;
    idpgroup: string;
    permissions: Array<string>;
    name: string;
    active: boolean;
    privilege: string;
    updatedAt: Number;
    description: string;
    id: string;
    groupIds?: Array<string>;
    resource?: Array<string>;
};

export type UserRolesListResponse = {
    items: UserRolesProps[];
};

// Devices
export enum StateAlertCode {
    L = 'L',
    L_PENDING = 'L_PENDING',
    LL = 'LL',
    LL_PENDING = 'LL_PENDING',
    H = 'H',
    H_PENDING = 'H_PENDING',
    HH = 'HH',
    HH_PENDING = 'HH_PENDING',
    N = 'N',
    N_PENDING = 'N_PENDING',
    U = 'U',
}

export interface AssetState {
    value: number;
    alertCode: StateAlertCode;
    updatedAt: number;
    prevValue?: number;
    prevAlertCode: StateAlertCode;
    prevUpdatedAt?: number;
}

export type AssetStates = { [measurement: string]: AssetState };

export enum DeviceState {
    inService = 'inService',
    disabled = 'disabled',
    deleted = 'deleted',
}

export interface Asset {
    name: string;
    deviceTypeId: string;
    invoiceId: string;
    lookupId: string;
    friendlyName: string;
    currentPath: string;
    friendlyCurrentPath: string;
    description: string;
    deviceState: DeviceState;
    physicalDeviceId: string;
    state: AssetStates;
    updatedAt: number;
}

// Alert Rules
export enum StateAlertConfigCode {
    L = 'L',
    LL = 'LL',
    H = 'H',
    HH = 'HH',
}

export enum AssetAlertRuleStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    DELETED = 'DELETED',
}

export enum IncidentStatus {
    OPEN = 'OPEN',
    CLEARED = 'CLEARED',
    RESOLVED = 'RESOLVED',
    ACKNOWLEDGED = 'ACKNOWLEDGED',
    FORWARDED = 'FORWARDED',
}

export interface AlertRule {
    id?: string;
    deviceId: string;
    measure: string;
    threshold_code: StateAlertConfigCode;
    threshold_value: number;
    deadband_period: number;
    escalation_plan: string;
    status: AssetAlertRuleStatus;
    forward: boolean;
    previous_version_id?: string;
    notification_frequency: Partial<Record<Exclude<IncidentStatus, IncidentStatus.RESOLVED | IncidentStatus.FORWARDED>, number>>;
    notes?: string;
    createdBy: string;
    updatedBy: number;
    createdAt: number;
    updatedAt: number;
}

// Dashboard
export interface HierarchyNode {
    id: string;
    name?: string;
    assetList?: string[];
    children: HierarchyNode[];
    isNew?: boolean;
    isDevice?: boolean;
    isRendered?: boolean;
};

export type HierarchyNodeWithColor = HierarchyNode & { color: string };

export interface MinimalNode {
    tagId: string,
    children?: MinimalNode[],
};

export interface Tag {
    id: string;
    name: string;
    assetList: string[];
    createdAt: number;
    updatedAt: number;
    hierarchyId: string;
}

export type Hierarchy = {
    id: string;
    name: string;
    hierarchyTreeData: MinimalNode[];
    updatedAt: Date;
    createdAt: Date;
};

export interface LocationNode extends HierarchyNode {
    title?: string;
    parent?: string;
    parentTitle?: string;
    children: LocationNode[];
}

export interface SelectProps {
    label?: string | undefined;
    value: string | undefined;
}

export enum AggregatedAlertCode {
    L = 'L',
    LL = 'LL',
    H = 'H',
    HH = 'HH',
    N = 'N',
    U = 'U',
}

export interface AggregateItem {
    friendlyName: string;
    friendlyCurrentPath: string;
    measurement: string;
    alertCode: AggregatedAlertCode;
    value: number;
}

// Incident Manager
export interface ContactChannelProps {
    name: string;
    active: false;
    detail: string;
    type: string;
    integratedId: string;
}

export interface Contact {
    id: string;
    alias: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    onCall: {
        start: number;
        end: number;
    };
    contactChannels: ContactChannelProps[]
    integratedId: string;
    notificationGroups: string[];
    active: boolean;
    updatedAt: number;
}

export interface NotificationGroup {
    contacts: { alias: string }[];
    updatedAt: number;
    description: string;
    id: string;
    name: string;
}

export interface EscalationStage {
    stage: number;
    notificationGroup: string;
    stageDuration: number;
}

export interface EscalationPlan {
    id: string;
    name: string;
    description: string;
    active: boolean;
    stages: EscalationStage[];
    updatedAt: number;
    integratedId?: string;
}

export enum IncidentActivity {
    RESOLUTION = 'RESOLUTION',
    ACKNOWLEDGE = 'ACKNOWLEDGE',
    OCCURRENCE = 'OCCURRENCE',
    CLEARANCE = 'CLEARANCE',
    NOTIFICATION = 'NOTIFICATION',
    ESCALATION = 'ESCALATION',
    COMMENT = 'COMMENT',
}

export interface Incident {
    id: string;
    resourceId: string;
    status: IncidentStatus;
    alertRule: string;
    escalationStage: number;
    activities: {
        type: IncidentActivity;
        value: string;
        user?: string;
        time: number;
    }[];
    updatedAt?: number;
    createdAt?: number;
}

export interface SelectedDevicesLocation {
    devices: SelectedDevice[],
    location: Location
}

export interface SelectedDevice {
    name: string,
    friendlyName: string
}

export interface Location {
    regionId: string;
    regionName: string;
    countryId: string;
    countryName: string;
    siteId: string;
    siteName: string;
    buildingId: string;
    buildingName: string;
    floorId: string;
    floorName: string;
    roomId: string;
    roomName: string;
}

export interface AssetProps {
    assetCategory: string;
    assetId: string;
}

export interface DeviceGroupData {
    assetAbsPaths: string[];
    assetPaths: string[];
    groupId: string;
    assets: AssetProps[];
    userRoleIds: string[];
    updatedAt: string;
    description: string;
    friendlyName: string;
}

export type LocationItem = {
    id: string;
    label: string;
    value: string;
    labelTag: string;
    description: string;
};
export type LocationType = 'region' | 'country' | 'site' | 'building' | 'floor' | 'room';
