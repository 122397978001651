import {
    Alert,
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    Header,
    Link,
    SelectProps,
    SpaceBetween,
    Table,
    Toggle,
} from '@cloudscape-design/components';
import { deviceManagerAPI, incidentManagerAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import { API_URL_PATH_DM_DEVICE, API_URL_PATH_DM_GROUP, API_URL_PATH_DM_RULE, API_URL_PATH_IM_PLANS } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import CreateAlertModal from '../CreateAlertModal';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { AlertRule, Asset, AssetAlertRuleStatus, DeviceGroupData, EscalationPlan, StateAlertConfigCode } from 'types/custom';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { thresholdCodeToName } from 'utils';
import AlertDetailsModal from '../AlertDetailsModal';
import { secondsToRelative, relativeToText } from '../CreateAlertModal/utils';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

const AlertsTable = ({ 
    filterByMeasurement,
    filterByThreshold,
    selectedDevices,
    selectedGroup,
    availableMeasurements,
    loading: parentLoading,
    onRulesChange
}: {
    selectedDevices?: Asset[],
    selectedGroup?: DeviceGroupData,
    availableMeasurements?: SelectProps.Options,
    filterByMeasurement?: string,
    filterByThreshold?: string,
    loading?: boolean,
    onRulesChange?: (ruleS: AlertRule[]) => void,
}) => {
    const { setNotification } = usePageLayoutContext();

    const [selectedRule, setSelectedRule] = useState<AlertRule[]>([]);
    const [allRules, setAllRules] = useState<AlertRule[]>([]);

    const [showAlertDetailsModal, setShowAlertDetailsModal] = useState(false);
    const [showCreateAlertModal, setShowCreateAlertModal] = useState(false);
    const [isRuleEdition, setIsRuleEdition] = useState(false);
    const [disableAllToggles, setDisableAllToggles] = useState(false);

    const [allEscalationPlans, setAllEscalationPlans] = useState<OptionDefinition[]>([]);

    const { items, collectionProps } = useCollection(allRules, {
        sorting: {},
    });

    const { data: plansList, isFetching: plansFetching, refetch: plansRefetch } = useFetchWithReactQuery<{ items: EscalationPlan[] }>({
        axiosInstance: incidentManagerAPI,
        url: API_URL_PATH_IM_PLANS,
        key: 'escalation-plans',
    });

    useEffect(() => {
        if (plansList) setAllEscalationPlans([
            {
                label: 'None',
                value: '',
                description: 'No notifications',
            },
            ...plansList?.items?.map((plan) => ({
                label: plan.name,
                value: plan.name,
                disabled: !plan.active,
                disabledReason: 'Activate this plan to use it',
                description: plan.description,
            })) || [],
        ]);
    }, [plansList]);


    const [selectedDevice] = selectedDevices || [];

    const referringEntity = selectedGroup ? 'group' : 'device';
    const referringName = selectedGroup ? selectedGroup.groupId : selectedDevice?.name;
    const referringCombo = `${referringEntity} ${referringName}`;

    const name = selectedGroup ? selectedGroup.groupId : selectedDevice?.name;
    const baseUrl = selectedGroup ? API_URL_PATH_DM_GROUP : API_URL_PATH_DM_DEVICE;
    const rulesUrl = `${baseUrl}/${name}/rules`;
    
    let rulesQuery = '';
    if (filterByMeasurement) {
        rulesQuery += `?measure=${filterByMeasurement}`;
    }

    if (filterByMeasurement && filterByThreshold) {
        rulesQuery += `&thresholdCode=${filterByThreshold}`;
    }

    const {
        data: rulesResponse,
        error: rulesError,
        isLoading: rulesLoading,
        isFetching: rulesFetching,
        status: rulesStatus,
        refetch: rulesRefetch
    } = useFetchWithReactQuery<AlertRule[]>({
        axiosInstance: deviceManagerAPI,
        url: rulesUrl + rulesQuery,
        key: `rules-${name}-${rulesQuery}`,
    });

    const deleteUrl = `${baseUrl}/rule?ids=${selectedRule[0]?.id}`;

    const {
        fetchData: deleteDeviceRule,
        loading: deleteDeviceRuleLoading,
        error: deleteDeviceRuleError,
    } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'DELETE',
            url: deleteUrl,
        },
        {
            manual: true,
        }
    );

    useEffect(() => {
        // For updating this, also ensures that toggles aren't being updated
        if (rulesStatus === 'success' && !disableAllToggles) {
            const newRules = rulesResponse?.filter((rule) => rule.status !== AssetAlertRuleStatus.DELETED) || [];
            setAllRules(newRules);
            onRulesChange?.(newRules);
        }
    }, [rulesStatus, rulesResponse]);

    const handleButtonDropdownClick = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
        event.preventDefault();

        if (event.detail.id === 'details') {
            setShowAlertDetailsModal(true);
        } else if (event.detail.id === 'create') {
            setShowCreateAlertModal(true);
            setIsRuleEdition(false);
        } else if (event.detail.id === 'edit') {
            setShowCreateAlertModal(true);
            setIsRuleEdition(true);
        } else if (event.detail.id === 'delete') {
            deleteDeviceRule()
                .then((response) => {
                    if (response && response.status === 200) {
                        rulesRefetch();

                        setNotification([
                            {
                                type: 'success',
                                content: `Deleted alert rule for ${referringCombo} successfully`,
                            },
                        ]);
                    } else {
                        setNotification([
                            {
                                type: 'error',
                                content: deleteDeviceRuleError || 'Bad request',
                            },
                        ]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const { fetchData: updateStatusForAlertRule } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'PATCH',
        },
        { manual: true }
    );

    const handleRuleToggle = async (rule: AlertRule, checked: boolean) => {
        rule.status = checked ? AssetAlertRuleStatus.ENABLED : AssetAlertRuleStatus.DISABLED;
        setDisableAllToggles(true);
        const response = await updateStatusForAlertRule(`${API_URL_PATH_DM_RULE}/${rule.id}/toggle`, {
            status: rule.status,
        } as Partial<AlertRule>);
        if (response && response.status >= 200 && response.status < 300) {
            setNotification([{
                type: 'success',
                content: `Updated alert rule status for ${referringCombo} successfully`,
            }]);
        } else {
            rule.status = checked ? AssetAlertRuleStatus.DISABLED : AssetAlertRuleStatus.ENABLED;
            setNotification([
                {
                    type: 'error',
                    content: response?.data?.message?.toString() || `Unexpected error occurred when updating alert rule status for ${referringCombo}`,
                },
            ]);
        }
        setDisableAllToggles(false);
        rulesRefetch();
    }

    if (rulesError) return <Alert type='error' header='Error loading alert rules'>{rulesError.toString()}</Alert>;

    const maxLowValue = allRules.length ? allRules.reduce((prev, current) => {
        return (prev && (prev.threshold_code === StateAlertConfigCode.L || prev.threshold_code === StateAlertConfigCode.LL) && prev.threshold_value > current.threshold_value) ? prev : current
    }).threshold_value : null;

    const minHighValue = allRules.length ? allRules.reduce((prev, current) => {
        return (prev && (prev.threshold_code === StateAlertConfigCode.H || prev.threshold_code === StateAlertConfigCode.HH) && prev.threshold_value < current.threshold_value) ? prev : current
    }).threshold_value : null;

    return (
        <>
            <Table
                {...collectionProps}
                onSelectionChange={({ detail }) =>
                    setSelectedRule(detail.selectedItems)
                }
                variant='embedded'
                selectedItems={selectedRule}
                columnDefinitions={[
                    {
                        id: 'status',
                        header: 'Enabled',
                        cell: (item: AlertRule) => (<Toggle
                            checked={item.status === AssetAlertRuleStatus.ENABLED}
                            onChange={({ detail }) => handleRuleToggle(item, detail.checked)}
                            disabled={disableAllToggles} />),
                        sortingField: 'status',
                    },
                    {
                        id: 'measure',
                        header: 'Measure',
                        cell: (item: AlertRule) => (<Link onFollow={() => {
                            setSelectedRule([item]);
                            setShowAlertDetailsModal(true);
                        }}>{snakeCaseToNormalCase(item.measure)}</Link>),
                        sortingField: 'measure',
                    },
                    {
                        id: 'type',
                        header: 'Type',
                        cell: (item: AlertRule) => (<Link onFollow={() => {
                            setSelectedRule([item]);
                            setShowAlertDetailsModal(true);
                        }}>{thresholdCodeToName(item.threshold_code)}</Link>),
                        sortingField: 'threshold_code',
                    },
                    {
                        id: 'threshold',
                        header: 'Threshold',
                        cell: (item: AlertRule) => item.threshold_value,
                        sortingField: 'threshold_value',
                    },
                    {
                        id: 'deadband',
                        header: 'Deadband',
                        cell: (item: AlertRule) => relativeToText(secondsToRelative(item.deadband_period), '') || (<i>Disabled</i>),
                        sortingField: 'deadband_period',
                    },
                    {
                        id: 'escalation-plan',
                        header: 'Escalation Plan',
                        cell: (item: AlertRule) => item.escalation_plan || (<i>None</i>),
                        sortingField: 'escalation_plan',
                    },
                ]}
                items={items}
                loadingText='Loading alert rules'
                loading={parentLoading || rulesLoading || deleteDeviceRuleLoading}
                selectionType='single'
                stripedRows
                empty={
                    <Box textAlign='center' color='inherit'>
                        <b>No alert rules</b>
                        <Box
                            padding={{ bottom: 's' }}
                            variant='p'
                            color='inherit'
                        >
                            No alert rules to display.
                        </Box>
                        <Button onClick={() => setShowCreateAlertModal(true)}>
                            Create Alert Rule
                        </Button>
                    </Box>
                }
                header={
                    <Header
                        counter={
                            selectedRule?.length
                                ? '(' +
                                selectedRule?.length +
                                '/' +
                                allRules.length +
                                ')'
                                : '(' + allRules.length + ')'
                        }
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button iconName='refresh' onClick={() => { rulesRefetch(); plansRefetch(); }} loading={rulesFetching || plansFetching} />
                                <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Create',
                                            id: 'create',
                                            disabled: false,
                                        },
                                        {
                                            text: 'Details',
                                            id: 'details',
                                            disabled: !selectedRule.length,
                                        },
                                        {
                                            text: 'Edit',
                                            id: 'edit',
                                            disabled: !selectedRule.length,
                                        },
                                        {
                                            text: 'Delete',
                                            id: 'delete',
                                            disabled: !selectedRule.length,
                                        },
                                    ]}
                                    onItemClick={handleButtonDropdownClick}
                                >
                                    Actions
                                </ButtonDropdown>
                            </SpaceBetween>
                        }
                    >
                        Alert Rules
                    </Header>
                }
            />

            {showAlertDetailsModal && (
                <AlertDetailsModal
                    visible={showAlertDetailsModal}
                    setVisible={setShowAlertDetailsModal}
                    alertRule={selectedRule[0]}
                />
            )}
            {showCreateAlertModal && selectedDevices && (
                <CreateAlertModal
                    visible={showCreateAlertModal}
                    setVisible={setShowCreateAlertModal}
                    rulesRefetch={rulesRefetch}
                    escalationPlansList={allEscalationPlans}
                    onCreate={() => setSelectedRule([])}
                    group={selectedGroup}
                    device={selectedGroup ? undefined : selectedDevices[0]}
                    availableMeasurements={availableMeasurements}
                    alertRule={isRuleEdition ? selectedRule[0] : undefined}
                />
            )}
            {maxLowValue && minHighValue && maxLowValue >= minHighValue && (
                <Alert type='warning' header='Thresholds overlap'>
                    The lowest value for high or high-high threshold is higher than the highest value for low or low-low threshold. Please adjust the thresholds to avoid unexpected results.
                </Alert>)
            }
        </>
    );
};

export default AlertsTable;
