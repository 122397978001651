import { useEffect, useState } from 'react';

import { Button, ContentLayout, FormField, Grid, Header, Select } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { DeviceManagerContext } from 'pages/device-manager/DeviceListPage';
import DeviceTable from 'components/device-manager/DeviceTable';
import { ThresholdPicker } from 'components/device-manager/DeviceAlertMgr/CreateAlertModal/ThresholdPicker';
import { deviceManagerAPI, userManagerAPI } from 'api';
import { toTitleCase } from 'utils';
import { Asset, DeviceGroupData } from 'types/custom';
import colors from '../../theme/colors';
import { API_URL_PATH_DM_DEVICES, API_URL_PATH_DM_GROUP, API_URL_PATH_GROUP_LIST } from 'constants/urls';
import AlertsTable from 'components/device-manager/DeviceAlertMgr/AlertsTable';

const AlertRulesManager = () => {
  const [selectedDevices, setSelectedDevices] = useState<Asset[]>([]);
  const [deviceGroupsOptions, setDeviceGroupsOptions] = useState<OptionDefinition[]>([]);
  const [selectedDeviceGroupOption, setSelectedDeviceGroupOption] = useState<OptionDefinition>({ label: 'All', value: '' });
  const [deviceGroups, setDeviceGroups] = useState<DeviceGroupData[]>([]);
  const [measurement, setMeasurement] = useState<OptionDefinition | null>(null);
  const [selectedThreshold, setSelectedThreshold] = useState<string>('');

  const { data: allDeviceGroups, isFetching: deviceGroupsLoading } =
    useFetchWithReactQuery({
      axiosInstance: userManagerAPI,
      key: 'device-groups',
      url: API_URL_PATH_GROUP_LIST,
  });

  useEffect(() => {
    if (allDeviceGroups) {
      setDeviceGroups(allDeviceGroups.items);
      const formattedList = allDeviceGroups.items.map(
        (el: DeviceGroupData) => {
          return { label: el.groupId, value: el.groupId };
        }
      );
      setDeviceGroupsOptions(formattedList);
      setSelectedDeviceGroupOption(formattedList[0]);
    }
  }, [allDeviceGroups]);

  const selectedGroup = deviceGroups.find(x => x.groupId === selectedDeviceGroupOption?.value);
  const deviceIds = selectedGroup?.assets?.map(x => x.assetId) ?? [];

  const { data: devicesInGroup, refetch, isFetching: isFetchingDevices } = useFetchWithReactQuery<Asset[]>({
    key: `device-group-devices-${selectedDeviceGroupOption.value}`,
    axiosInstance: deviceManagerAPI,
    url: `${API_URL_PATH_DM_DEVICES}/${deviceIds.join(',')}`,
    enabled: selectedDeviceGroupOption.value !== '',
    placeholderData: []
  });

  const mappedMeasurements = devicesInGroup.reduce(
    (prev, current) => [...prev, ...Object.keys(current.state)],
    [] as string[]
  );

  const allMeasurements = mappedMeasurements.length > 0 ? mappedMeasurements : []

  const measurementOptions = allMeasurements.map((x: string) => ({
    label: toTitleCase(x.replace(/_/gm, ' ')),
    value: x,
  }));

  return (
    <ContentLayout
      defaultPadding
      headerBackgroundStyle={colors.accentGreen2}
      header={<Header variant="h1">Alert Rules Manager</Header>}
    >
      <div style={{ maxWidth: '230px', marginBottom: '20px' }}>
        <Select
          inlineLabelText="Device Group"
          options={deviceGroupsOptions}
          selectedOption={selectedDeviceGroupOption}
          loadingText="Loading groups"
          placeholder="Choose a group"
          statusType={deviceGroupsLoading ? "loading" : 'finished'}
          onChange={(event) =>
            setSelectedDeviceGroupOption(event.detail.selectedOption)
          }
        />
      </div>

      <DeviceManagerContext.Provider
        value={{
          deviceLoading: isFetchingDevices,
          allDevices: devicesInGroup,
          getAllDevices: refetch,
          setSelectedDevices,
          selectedDevices,
          deviceError: null,
          activeTabId: '',
          setActiveTabId: () => { },
          refetchDeviceDetails: () => { },
          deviceResponse: {},
          deviceStatus: '',
          deviceFetching: false,
          syncDevices: (async () => { }) as any,
          isSyncingDevices: false,
        }}
      >
        <DeviceTable />
      </DeviceManagerContext.Provider>

      <div>
        <div
          style={{
            display: 'flex',
            gap: '13px',
            alignItems: 'center',
            paddingTop: '20px',
            paddingBottom: '20px',
            height: '100%',
          }}
        >
          <div style={{ width: '230px' }}>
            <Select
              inlineLabelText="Measurement"
              placeholder='Select a measurement'
              options={measurementOptions}
              selectedOption={measurement}
              onChange={(event) => setMeasurement(event.detail.selectedOption)}
            />
          </div>
          <div style={{
            paddingTop: '10px'
          }}>
            <ThresholdPicker disabled={!measurement} selectedId={selectedThreshold} onChange={setSelectedThreshold} />
          </div>
          <div style={{
            paddingTop: '10px'
          }}>
            <Button onClick={() => {
              setSelectedThreshold('');
              setMeasurement(null);
            }}
              iconName='remove'
              variant='icon'
              iconAlt='Clear'
              disabled={!measurement}
            />
          </div>
        </div>
      </div>

      {devicesInGroup.length > 0 && (
        <div style={{ marginBottom: '20px' }}>
          <AlertsTable
            availableMeasurements={measurementOptions}
            selectedDevices={devicesInGroup}
            selectedGroup={selectedGroup}
            filterByMeasurement={measurement?.value}
            filterByThreshold={selectedThreshold}
            loading={isFetchingDevices}
          />
        </div>
      )}

    </ContentLayout>
  );
};

export default AlertRulesManager;
