import {
    Box,
    Button,
    FormField,
    Modal,
    Multiselect,
    SpaceBetween,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { deviceManagerAPI, userManagerAPI } from 'api';
import {
    API_URL_PATH_DM_DEVICE_LIST,
    API_URL_PATH_GROUP_LIST,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { useEffect, useState } from 'react';
import { Asset, DeviceGroupData } from 'types/custom';

interface AssetProps {
    assetCategory: string;
    assetId: string;
}

const AddRemoveAssetModal = ({
    selectedGroup,
    visible,
    onDiscard,
    action,
    refetch,
}: {
    selectedGroup: DeviceGroupData[];
    visible: boolean;
    onDiscard: () => void;
    action: string;
    refetch: () => void;
}) => {
    const [allAssets, setAllAssets] = useState<OptionDefinition[]>();
    const [selectedAssets, setSelectedAssets] = useState<
        readonly OptionDefinition[]
    >([]);
    const [assetPayload, setAssetPayload] = useState<AssetProps[]>();

    const {
        data: devicesList,
        error: devicesError,
        status: devicesStatus,
        isLoading: devicesLoading,
    } = useFetchWithReactQuery<{ items: Asset[] }>({
        axiosInstance: deviceManagerAPI,
        url: API_URL_PATH_DM_DEVICE_LIST,
        key: 'devices',
    });

    useEffect(() => {
        if (devicesList && devicesStatus === 'success') {
            if (action === 'Add') {
                const selectedGroupDeviceNameArray = (selectedGroup[0]?.assets || []).map(asset => asset?.assetId);
                setAllAssets(devicesList.items.map((device: Asset) => ({
                    disabled: selectedGroupDeviceNameArray.includes(device.name),
                    label: device.friendlyName,
                    description: device.description,
                    labelTag: device.name,
                    value: device.name,
                })));
            } else if (action === 'Remove') {
                setAllAssets(selectedGroup[0].assets.map((asset) => {
                    const device = devicesList.items.find(item => asset.assetId === item.name);
                    return {
                        label: device?.friendlyName || asset.assetId,
                        description: device?.description || '',
                        labelTag: asset.assetId,
                        value: asset.assetId,
                    }
                }));
            }
        }
    }, [devicesList, devicesStatus]);

    const {
        fetchData: addRemoveAssets,
        error: addRemoveAssetsError,
        loading: addRemoveAssetsLoading,
        status: addRemoveAssetsStatus,
    } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'PATCH',
            url: `${API_URL_PATH_GROUP_LIST}/${selectedGroup[0]?.groupId}/${action === 'Add' ? 'addassets' : 'removeassets'}`,
            data: {
                assets: assetPayload,
            },
        },
        { manual: true }
    );

    useEffect(() => {
        if (assetPayload?.length) {
            addRemoveAssets();
        }
    }, [assetPayload]);

    useEffect(() => {
        if (addRemoveAssetsStatus === 200) {
            refetch();
            onDiscard();
        }
    }, [addRemoveAssetsStatus]);

    const handleSubmit = () => {
        const assetsObj = selectedAssets.map((asset) => ({
            assetCategory: 'device',
            assetId: asset.value || '',
        }));

        setAssetPayload(assetsObj);
    };

    return (
        <Modal
            onDismiss={onDiscard}
            visible={visible}
            closeAriaLabel='Close modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={onDiscard}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleSubmit}
                            loading={addRemoveAssetsLoading}
                            disabled={addRemoveAssetsLoading || !selectedAssets.length}
                            disabledReason={!selectedAssets.length ? 'Select at least one asset' : 'Loading'}
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`${action} device(s)`}
        >
            <FormField label='Assets'>
                <Multiselect
                    selectedOptions={selectedAssets}
                    onChange={({ detail }) =>
                        setSelectedAssets(detail.selectedOptions)
                    }
                    filteringType='auto'
                    deselectAriaLabel={(e) => `Remove ${e.label}`}
                    options={allAssets}
                    placeholder='Choose options'
                    selectedAriaLabel='Selected'
                    loadingText='Loading assets'
                    statusType={devicesLoading ? 'loading' : 'finished'}
                    errorText={String(devicesError)}
                    empty='No options'
                />
                {addRemoveAssetsError && <p>{addRemoveAssetsError}</p>}
            </FormField>
        </Modal>
    );
};

export default AddRemoveAssetModal;
